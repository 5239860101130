/* ThemedTodoItem.css */
.dropdown-option.pulsing-effect {
  animation: pulse 1.5s infinite;
  color: white;
  padding: 5px;
  margin-top: 5px;
  text-align: center;
  background-color: black;
  font-family: 'Hellscourt', sans-serif;
  width: 90%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

/* Existing styles */
.todo-item {
  position: relative; /* Ensure relative positioning for absolute children */
  background-size: cover;
  background-position: center;
  padding: 5px;
  margin: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.5);
}

.add-to-my-day-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.todo-icon {
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin: 0 5px;
}

.completed {
  text-decoration: line-through;
  opacity: 0.6;
}

.starred {
  order: -1;
}

.todo-text {
  font-family: 'Hellscourt', sans-serif;
  color: white;
  text-shadow: 
      -1px -1px 0 black,  
      1px -1px 0 black,
      -1px  1px 0 black,
      1px  1px 0 black;
  font-size: 1.5rem;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(100% - 110px);
  display: flex;
  align-items: center;
  transition: font-size 0.5s;
}

.todo-text:hover {
  overflow: visible;
  white-space: normal;
  font-size: 2.5rem;
}

.dropdown-menu {
  position: absolute;
  background-color: black;
  border: 1px solid white;
  z-index: 10;
  padding: 5px;
  width: 35%;
}

.dropdown-option {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: black;
  color: white;
  border: none;
  cursor: pointer;
  font-family: 'Hellscourt', sans-serif;
}

.dropdown-option:hover {
  background-color: #666;
}

.draggable-todo {
  width: 95%;
}

.draggable-todo .todo-item {
  width: 95%;
}

/* ThemedTodoItem.css */

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 5px;
  border: 1px solid white;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position above the icon */
  left: 50%;
  transform: translateX(-50%);
  font-family: 'Hellmuth';
  font-size: 14px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
