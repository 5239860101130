.weather-container {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    right: 15px;
    top: 60px;
    background-color: rgba(0, 0, 0, 0.6); /* Black with 80% opacity */
    z-index: 1000;
    font-size: 2rem;
    height: 50px;
    width: 120px;
    border-radius: 15px;
}


.icon-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 50%;
}

.temp-and-des-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 50%;
}

.temp-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50%;
    width: 100%;
}

.des-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 50%;
    width: 100%;
}

.description-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    font-size: .6rem;
    color: white;
    width: 50px;
    font-family: 'hellmuth';
  
}

.weather-temp-text {
    color: white;
    font-size: .75rem;
    font-family: 'hellmuth';
}

.weather-pic-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50%;
    width: 50%;
}

.weather-button {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    right: 60px;
    top: 13px;
    z-index: 1000;
    height: 50px;
    width: 45px;
    height: 45px;
}