.graveyard-page {
    background-image: url('/graveyard-background.jpg');
    background-size: cover;
    background-position: center;
  }
  
  .graveyard-content-container {
    background: none; /* Remove the inherited background */
  }
  
  .graveyard-header {
    display: flex;
    justify-content: center; /* Ensure the arrows and month/year are spaced out */
    align-items: center;
    margin: 0px;
  }
  
  .graveyard-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;
    padding: 10px;
  }
  
  .graveyard-day-header,
  .graveyard-day {
    background: none; /* Remove the background */
    color: white;
    text-align: center;
    font-size: .75em;
  }
  
  .graveyard-day {
    position: relative;
    cursor: pointer;
  }
  
  .tombstone-image {
    width: 100%;
    height: auto;
  }
  
  .day-number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 1.5em;
    font-weight: bold;
  }
  
  .arrow-button-left-arrow-graveyard,
  .arrow-button-right-arrow-graveyard {
    width: 40px;
  }