
.app-wrap {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    align-items: flex-start;
    height: 100%;
    width: 100%;
}

.big-container {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    align-items: flex-start;
    height: 86%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

.buffer-top {
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-items: flex-start;
    height: 10%;
    width: 100%;
}


.daily-demon {
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
    align-items: flex-start;
    height: 100%;
    border-color: white;
}

.heading-content-container {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    height: 10%;
    width: 100%;
}

.swipe {
    position: absolute;
    height: 50px;
    width: 50px;
    top: 13%;
    left: 5%;
    transform: rotate(-15deg);
}

.sports-and-stocks-container {
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-items: flex-start;
    height: 20%;
    width: 94%;
    background-color: rgba(114, 111, 111, 0.7);
    border-radius: 20px;
    margin-left: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
    padding: 2px;
}

.sports-container {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    align-items: flex-start;
    height: 100%;
    width: 70%;
}

.stocks-container {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    align-items: flex-start;
    height: 95%;
    width: 28%;
    margin-top: 2px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    margin-left: 4px;
}

.articles-container {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    align-items: flex-start;
    max-height: 420px; /* This sets the visible area of the articles */
    width: 96%;
    background-color: rgba(114, 111, 111, 0.7);
    border-radius: 20px;
    margin-left: 3px;
    overflow-y: auto; /* Enables vertical scrolling */
}

.articles-container::-webkit-scrollbar {
    width: 8px;
}

.articles-container::-webkit-scrollbar-thumb {
    background-color: darkgray;
    border-radius: 10px;
}

.individual-art-container {
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-items: flex-start;
    min-height: 210px; /* Set a fixed height for each article */
    width: 94%;
    margin: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    background-size: 325px 200px;
    text-decoration: none;
    color: black;
}


.featured-article-container {
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-items: flex-start;
    min-height: 200px; /* Set a fixed height for each article */
    width: 94%;
    margin: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    /* background-image: url("/paper-2.jpg"); */
    background-size: 325px 200px;
    overflow: hidden;
    
}

.individual-art-container-2 {
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-items: flex-start;
    min-height: 200px; /* Set a fixed height for each article */
    width: 94%;
    margin: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    /* background-image: url("/paper-3.jpg"); */
    background-size: 325px 200px;
    text-decoration: none;
    color: black;
}

.individual-art-container-3 {
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-items: flex-start;
    min-height: 200px; /* Set a fixed height for each article */
    width: 94%;
    margin: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    /* background-image: url("/paper-4.jpg"); */
    background-size: 325px 200px;
}

.featured-article-link {
    text-decoration: none; /* Removes underline on links */
    color: inherit; /* Ensures the text keeps the same color */
    display: block; /* Ensures it behaves like a block element */
}
