
.hell-news-button {
    font-family: 'Hellmuth';
    font-size: .75rem;
    position: absolute;
    left: 130px;
    top: 13px;
    z-index: 1;
    height: 30px;
    width: 85px;
    color: lightgrey;
    text-decoration: none;
}

