.heading {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: .4rem;
    margin-right: 1.5px;
}

.positive {
    color: rgb(129, 214, 1); 
}

.negative {
    color: red; 
}
