.stock-name {
    font-family: "hellmuth";
    font-size: .25rem;
    color: lightgray;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1px;
}

.divider{ 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 20%;
    width: 100%;
}

.acronym {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 20%;
    width: 100%;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: .4rem;
    margin-top: 2px;
}