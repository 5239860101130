@font-face {
  font-family: 'hellmuth';
  src: url('/hellmuth.ttf') format('truetype');
}

@font-face {
  font-family: 'hellscourt';
  src: url('/Hellscourt.ttf') format('truetype');
}

.signup-container {
  text-align: center;
  font-family: 'hellscourt', sans-serif;
  color: white;
  background: url('/modal-center-content-block.png') no-repeat center center;
  padding: 10px;
  border-radius: 30px;
}

.signup-container h2 {
  font-family: 'hellscourt', sans-serif;
  color: white;
}

.signup-container input {
  display: block;
  margin: 10px auto;
  padding: 10px;
  width: 80%;
  font-family: 'hellmuth', sans-serif;
}

.signup-container button {
  background: grey;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-family: 'hellscourt', sans-serif;
}

.signup-container button:hover {
  opacity: 0.8;
}

.sign-up-button {
  background: grey;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-family: 'hellscourt', sans-serif;
}

/* src/components/SignIn/SignIn.css and src/components/SignUp/SignUp.css */

.sign-out-button {
  background: grey;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-family: 'hellscourt', sans-serif;
}

.sign-out-button:hover {
  opacity: 0.8;
}
