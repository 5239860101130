.darkening-container {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    align-items: flex-start;
    height: 90%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

.main-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 100%;
    width: 100%;
}

.buffer-top {
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-items: flex-start;
    height: 10%;
    width: 100%;
}

.article-wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 88%;
    width: 95%;
    background-color: rgba(114, 111, 111, 0.7);
    border-radius: 30px;
    margin-left: 3px;
}

.headline-2 {
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-items: flex-start;
    height: 5%;
    width: 90%;
    background-color: rgba(240, 230, 230, 0.7);
    font-family: "hellmuth";
    padding-left: 10px;
    padding-top: 5px;
    border-radius: 10px;
    margin-top: 7px;
    margin-left: 5px;
    font-size: clamp(0.8rem, 4vw, 1.2rem); /* Dynamically adjust font size */
    font-weight: bold;
    text-align: left;
    white-space: nowrap; /* Prevents wrapping */
    overflow: hidden; /* Ensures content fits within the container */
    text-overflow: ellipsis; /* Adds "..." if text is too long */
}


.subheadlines-2 {
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-items: flex-start;
    height: 2%;
    width: 90%;
    font-family: "bree-serif";
    font-size: .6rem;
    line-height: .9;
    text-align: left;
    padding: 7px;
    background-color: rgba(240, 230, 230, 0.7);
    margin-top: 5px;
    border-radius: 7px;
    margin-left: 5px;
}

.image-container-2 {
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-items: flex-start;
    height: 35%;
    width: 95%;
    margin-top: 5px;
}

.image-caption-2 {
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-items: flex-start;
    width: 93%;
    background-color: rgba(114, 111, 111, 0.7);
    margin-top: 5px;
    border-radius: 10px;
    margin-left: 1px;
    font-size: .6rem;
    padding-left: 7px;
    padding-top: 7px;
    padding-bottom: 7px;
    /* Set height to auto */
    height: auto;
    line-height: 1.2;
    text-align: left;
}


.byline-2 {
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-items: flex-start;
    height: 3%;
    width: 90%;
    font-family: "hellmuth";
    font-size: .7rem;
    padding-left: 3px;
    padding-top: 3px;
    margin-top: 3px;
    margin-bottom: 3px;
}

.article-copy-2 {
    display: flex;
    flex-direction: column; /* To ensure paragraphs stack vertically */
    justify-content: flex-start;
    align-items: flex-start;
    height: 40%; /* Restrict the height to ensure the overflow can trigger */
    width: 90%;
    background-color: rgba(240, 230, 230, 0.7);
    border-radius: 20px;
    padding: 6px;
    font-size: .6rem;
    text-align: justify;
    overflow-y: auto; /* Enable vertical scrolling when content overflows */
    line-height: 1.4; /* Adjust for better readability */
    margin-bottom: 5px;
}

.article-image-2 {
    width: 100%; 
    height: 100%; 
    border-radius: 10px; 
    object-fit: cover;
    margin-bottom: 3px; 
}

.indented-paragraph {
    text-indent: 1.5em; /* Indent for paragraphs */
    margin-bottom: .5px; /* Adjust this value to reduce space between paragraphs */
}
