/* index.css */
@font-face {
  font-family: 'Hellbone-demo';
  src: url('/Hellbone-Demo.ttf') format('truetype'); /* Adjust the path to your font file */
}

@font-face {
  font-family: 'Dystopian-future';
  src: url('/Dystopian\ Future.ttf') format('truetype');
}

@font-face {
  font-family: 'Hellscourt';
  src: url('/Hellscourt.ttf') format('truetype');
}

@font-face {
  font-family: 'hellmuth';
  src: url('./hellmuth.ttf') format('truetype');
}

body {
  display: flex;
  flex-direction: column;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  min-width: 100vw;
  background: linear-gradient(45deg, #8B0000, #FF4500, #808080, #000000);

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100vh;
}
