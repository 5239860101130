.stock-placcard {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 23%;
    width: 97%;
    margin-left: 2px;
    margin-top: 5px;
    background-color: rgba(114, 111, 111, 0.7);
    border-radius: 5px;
}

.left-side {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 50%;  
}

.stock-name {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 100%;
}

.right-side {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 50%;
}

.graph-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 50%;
}

.numbers-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 50%;
  
}

.percentage {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 50%;
    width: 100%;
}

.total {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 50%;
    width: 100%;
}