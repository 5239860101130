.app-container-tombstone {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.my-day-container {
  font-family: 'hellmuth', sans-serif;
  font-size: 1.2em; /* Adjust the size as needed */
}

.tombstone-content-container {
  flex-grow: 1;
  overflow-y: auto;
  max-height: 80vh; /* Adjust as needed */
}

.footer-container {
  margin-top: auto;
}

.my-day-tombstone {
  font-family: 'Hellmuth', sans-serif;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: black;
  font-size: 2rem;
  letter-spacing: 5px;
  text-shadow: 
    -1px -1px 0 white,  
    1px -1px 0 white,
    -1px  1px 0 white,
    1px  1px 0 white;
  margin-top: 35px;
}

.deleted-todo-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 95%;
  height: 15%;
  background-color: rgba(0, 0, 0, 0.5); /* Black with 50% transparency */
  border-radius: 10px; /* Adjust the value to change the roundness */
  margin-bottom: 5px;
  margin-left: 5px;
}


.deleted-todo-text {
  font-family: 'Hellscourt';
  color: black;
  font-size: 2rem;
  letter-spacing: 5px;
  text-shadow: 
    -1px -1px 0 white,  
    1px -1px 0 white,
    -1px  1px 0 white,
    1px  1px 0 white;
}
