.vault-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  background: transparent;
}

.vault-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
}

.vault-input {
  font-family: 'Hellscourt', sans-serif;
  padding: 10px;
  font-size: 1.5rem;
  margin: 10px;
  width: 80%;
}

.add-scroll-button {
  font-family: 'Hellscourt', sans-serif;
  padding: 10px;
  margin: 10px;
  background-color: #fff;
  border: none;
  cursor: pointer;
}

.scrolls-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-height: 60vh;
  overflow-y: auto;
}

.draggable-scroll {
  width: 370px;
  height: 170px;
  background-size: cover;
  margin: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scroll-title {
  font-family: 'Hellmuth', sans-serif;
  color: white;
  text-shadow: 
    -1px -1px 0 black,  
    1px -1px 0 black,
    -1px  1px 0 black,
    1px  1px 0 black;
  text-align: center;
}

.scroll {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 170px;
}

.vault-page a {
  text-decoration: none;
  color: inherit; /* Ensure the color inheritance is correct */
}

.vault-page a:visited {
  color: inherit; /* Ensure visited links do not change color */
}
