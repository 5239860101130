/* Updated App.css */

@font-face {
  font-family: 'Hellscourt';
  src: url('./fonts/Hellscourt.ttf') format('truetype');
}

@font-face {
  font-family: 'hellmuth';
  src: url('./hellmuth.ttf') format('truetype');
}

.App {
  text-align: center;
  background-color: #333;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Arial, sans-serif;
}

.app-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  min-width: 103vw;
}

.my-day {
  font-family: 'Hellbone-demo', sans-serif;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: black;
  font-size: 5rem;
  letter-spacing: 5px;
  text-shadow: 
    -1px -1px 0 white,  
    1px -1px 0 white,
    -1px  1px 0 white,
    1px  1px 0 white;
  margin: 0;
  padding: 10px;
}

.my-day-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 10px;
}

.footer-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to bottom, rgba(51, 49, 49, 0.8), rgba(51, 49, 49, 0.3)); /* Slightly more opaque to ensure readability */
  min-height: 10%;
  width: 100vw;
  padding: 10px;
  margin-top: 5px;
  z-index: 3; /* Ensure it is above the sidenav */
}

/* Other existing styles remain unchanged */


.hamburger-icon, .add-todo-icon, .satan-speaks-icon, .separator-line {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 33%;

}

.hamburger-icon-image, .add-todo-icon-image, .satan-speaks-icon-image, .separator-line-satan-image {
  width: 50%;
  margin: 5px 0 10px;
  cursor: pointer;
}

.todo-content-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 30px;
  height: 80%;
  width: 100%;
  overflow-y: auto;
}

.todo-input {
  display: flex;
  flex: 1;
  padding: 10px;
  font-size: 2.5rem;
  font-family: 'Hellscourt', sans-serif;
  background-color: black;
  color: white;
  width: 70vw;
}

.add-todo-button {
  padding: 10px;
  background-color: #fff;
  border: none;
  cursor: pointer;
  font-family: 'Hellscourt', sans-serif;
}

.todo-item {
  background-size: cover;
  background-position: center;
  padding: 5px;
  margin: 10px;
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center; /* Align items vertically */
  justify-content: space-between;
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.5);
}

.todo-text {
  font-family: 'Hellscourt', sans-serif;
  color: white;
  text-shadow: 
    -1px -1px 0 black,  
    1px -1px 0 black,
    -1px  1px 0 black,
    1px  1px 0 black;
  font-size: 1.5rem;
  flex-grow: 1; /* Take up remaining space */
  overflow: hidden; /* Hide any overflowing text */
  text-overflow: ellipsis; /* Add an ellipsis for overflow text */
  white-space: nowrap; /* Prevent wrapping */
  max-width: calc(100% - 110px); /* Limit text width to allow space for icons */
  display: flex; /* Use flexbox */
  align-items: center; /* Center text vertically */
  transition: font-size 0.5s; /* Smooth transition for font size */
}

.todo-text:hover {
  overflow: visible;
  white-space: normal;
  font-size: 2.5rem; /* Increase font size on hover */
}

.todo-icon {
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin: 0 5px;
}

.completed {
  text-decoration: line-through;
  opacity: 0.6;
}

.starred {
  order: -1; /* Push starred todos to the top */
}

.icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.placardImages {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 20%;
  width: 100%;
}

.todo-input-container {
  position: fixed;
  bottom: 20%;
  left: 46%;
  transform: translateX(-50%);
  background-color: rgba(51, 49, 49, 0.9);
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  z-index: 1; /* Ensure it appears above other content */
}

.dropdown-menu {
  position: absolute;
  background-color: black;
  border: 1px solid white;
  z-index: 10;
  padding: 5px;
}

.dropdown-option {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: black;
  color: white;
  border: none;
  cursor: pointer;
  font-family: 'Hellscourt', sans-serif;
}

.dropdown-option:hover {
  background-color: #666;
}

/* Add to your CSS file */
.draggable-todo {
  width: 95%;
}

.draggable-todo .todo-item {
  width: 95%; /* Ensure the todo item takes up full width */
}

.add-to-my-day-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.side-nav .other-drop-down-styles {
  color: #818181 !important;
}

