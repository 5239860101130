/* Calendar.css */

@font-face {
  font-family: 'devlisummoner';
  src: url('/devilsummoner.ttf') format('truetype');
}

@font-face {
  font-family: 'hellmuth';
  src: url('/hellmuth.ttf') format('truetype');
}

.app-container {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 103vw;
}

.my-day-container {
  text-align: center;
  margin: 10px;
  margin-bottom: 0px;
}

.calendar-page {
  background-image: url('/calendar-background-wallpaper.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.calendar-content-container {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
  margin-top: 30px; /* Move the calendar content down */
  background-image: url('/calendar-background-image.png'); /* Calendar content background */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.calendar-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%; /* Adjusted to make space for the buttons */
  margin-bottom: 10px; /* Reduced gap between month and days */
  z-index: 1;
  padding: 20px;
  letter-spacing: 5px;
  position: relative;
  margin-top: 30px; /* Move the header down more */
}

.month-title {
  font-size: 2rem; /* Reduced size */
  text-shadow: 2px 2px 4px #000;
  color: white;
  font-family: 'hellmuth', sans-serif; /* Apply new font */
  text-align: center;
}

.year {
  font-size: 1.5rem; /* Smaller year */
  margin-top: 5px; /* Add space between month and year */
}

.arrow-button {
  position: absolute;
  width: 40px;
  height: 40px;
  cursor: pointer;
  top: 100%; /* Move the arrow buttons further down */
  transform: translateY(-50%);
}

.left-arrow {
  left: -15px; /* Adjusted space from month title */
}

.right-arrow {
  right: -15px; /* Adjusted space from month title */
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
  width: 90%;
  max-width: 400px; /* Ensures the calendar fits within mobile screens */
  z-index: 1;
  margin-top: 20px; /* Move the grid down more */
}

.calendar-day-header, .calendar-day {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background: rgba(0, 0, 0, 0.7);
  border: 1px solid #000;
  border-radius: 5px;
  font-size: 1.2rem;
  text-shadow: 1px 1px 2px #000;
  color: white;
  font-family: 'devlisummoner', sans-serif; /* Apply new font */
}

.current-day {
  background: rgba(255, 0, 0, 0.7); /* Highlight current day */
}

.footer-container {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: linear-gradient(to bottom, rgb(51, 49, 49), rgba(51, 49, 49, 0));
}
