.scroll {
  width: 200px;
  height: 150px; /* Adjust height to be similar to todos */
  background-size: cover;
  margin: 10px;
  position: relative;
  display: flex;
  justify-content: space-between; /* Space between title and icons */
  align-items: center;
  padding: 0 10px; /* Add some padding */
  
}

.scroll-title {
  font-family: 'Hellmuth', sans-serif;
  color: white;
  text-shadow: 
    -1px -1px 0 black,  
    1px -1px 0 black,
    -1px  1px 0 black,
    1px  1px 0 black;
  text-align: center;
  flex-grow: 1; /* Allow the title to take up available space */
  margin-right: 10px;
  padding-right: 30px; /* Add some space between title and icons */
}

.scroll-icons {
  display: flex;
  align-items: center;
}

.edit-icon-scroll  {
  width: 40px;
  height: 40px;
  margin-left: 10px;
  cursor: pointer;
}

.delete-icon-scroll {
  width: 40px;
  height: 40px;

  cursor: pointer;
}

.scroll a {
  text-decoration: none;
  color: inherit;
}

.scroll a:visited {
  color: inherit;
}

