.theme-switcher-container {
  position: fixed;
  top: 10px;
  right: 20px;
  z-index: 1000; /* Ensure it is above other elements */
}

.theme-circle-button {
  cursor: pointer;
  width: 50px;
  height: 50px;
}

.theme-options {
  position: absolute;
  top: 60px; /* Adjust based on the size of your button */
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.theme-option-button {
  cursor: pointer;
  width: 100px; /* Adjust based on your image size */
  height: auto;
  border-radius: 5px;
}
