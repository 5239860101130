.score-container {
    display: flex;
    font-family: "hellmuth";
    font-size: 1.25rem;
    color: lightgray;
  }
  
  .score-digit {
    display: inline-block;
    animation: roll 0.5s ease-in-out;
    transform-origin: center;
  }
  
  @keyframes roll {
    0% {
      transform: rotateX(0);
      opacity: 0.5;
    }
    50% {
      transform: rotateX(90deg);
      opacity: 0;
    }
    100% {
      transform: rotateX(0);
      opacity: 1;
    }
  }
  