.stocks-headline {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: "hellmuth";
    color: lightgray;
    font-size: .4rem;
    margin-top: 3px;
    margin-left: 3px;
}

.parent-div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 100%;
}

.headline-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    height: 10%;
    width: 100%;
    margin-left: 3px;
}

.arrow {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    width: 100%;
   
}

.arrow-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    width: 10%;
    margin-top: 2px;
    margin-left: 2px;
   
}
