.upcoming-date {
  font-family: 'hellmuth';
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  margin: 5px 0;
  border-radius: 5px;
  color: white;
  margin-left: 20px;
  position: relative; /* Ensure the dropdown is positioned relative to this container */
}

.upcoming-date-link {
  color: white;
  text-decoration: none;
}

.upcoming-date-link:hover {
  text-decoration: underline;
}

.delete-date-button {
  background: none;
  border: none;
  cursor: pointer;
  height: 20px;
}

.preview-button {
  background-color: transparent;
  border-color: transparent;
  height: 40px;
  cursor: pointer;
}

.todo-preview-dropdown {
  background-image: url("/tombstone-placard.png");
  font-family: "hellscourt";
  background-color: #333;
  padding: 10px;
  border-radius: 5px;
  width: 100%; 
  position: absolute; 
  top: 100%; 
  left: 0; 
  z-index: 1;
  text-align: left; 
}

.todo-preview-item {
  color: white;
  padding: 5px 0;
}
