.team-images-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.teamPic {
    display: block;
    width: 100%;
    height: 100%; 
    margin: 0;
    padding: 0;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
    margin-bottom: 5px;
}

.teamPicLeft {
    display: block;
    width: 100%;
    height: 100%; 
    margin: 0;
    padding: 0;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
    margin-bottom: 5px;
}

.team-names {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: 100%;
    font-family: "hellmuth";
    color: lightgray;
    font-size: .6rem;
}

.center-sports-elements {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 49%;
    height: 100%;
}

.score {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 10%;
    height: 10%;
    font-family: "hellmuth";
    color: lightgray;

}

.game-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    height: 100%;
}

.game-type {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95%;
    height: 10%;
    font-family: "hellmuth";
    color: lightgray;
    font-size: .6rem;
    margin-bottom: 8px;
}

.arena-pic {
    display: flex;
    justify-content: center; 
    align-items: center; 
    width: 60%; 
    height: auto; 
    margin-bottom: 3px;
}

.live-now {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 95%;
    height: 10%;
    font-family: "hellmuth";
    color: lightgray;
    font-size: .5rem;
}

.live-now-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 65%;
    height: 10%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
    margin-bottom: 2px;
}

.arena-pic-styles {
    max-width: 100%; /* Ensure the image doesn't exceed container size */
    height: auto; /* Maintain aspect ratio */
    border-radius: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
}
