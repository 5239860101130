@font-face {
    font-family: 'hellmuth';
    src: url('./hellmuth.ttf') format('truetype');
  }

.log-in-button {
    font-family: 'Hellmuth';
    font-size: .75rem;
    position: absolute;
    left: 20px;
    top: 13px;
    z-index: 1;
    height: 50px;
    width: 85px;
    color: lightgrey;
}