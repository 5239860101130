/* src/components/CalendarTodos/CalendarTodos.css */

@font-face {
    font-family: 'hellmuth';
    src: url('/hellmuth.ttf') format('truetype');
  }
  
  .app-container {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .my-day-2 {
    font-family: 'hellmuth', sans-serif;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    color: black;
    font-size: 3rem;
    letter-spacing: 5px;
    text-shadow: 
      -1px -1px 0 white,  
      1px -1px 0 white,
      -1px  1px 0 white,
      1px  1px 0 white;
    margin: 0;
    padding: 10px;
    padding-top: 50px;
  }
  
  .my-day-container-2 {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 10px;
  }
  
  .todo-content-container {
    flex: 1;
    overflow-y: auto; /* Ensure todos are scrollable */
  }
  
  .todo-content-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 30px;
    height: 80%;
    width: 100%;
    overflow-y: auto;
  }
  
  .todo-input {
    display: flex;
    flex: 1;
    padding: 10px;
    font-size: 1rem;
    font-family: 'hellmuth', sans-serif;
    background-color: black;
    color: white;
  }
  
  .add-todo-button {
    padding: 10px;
    background-color: #fff;
    border: none;
    cursor: pointer;
    font-family: 'hellmuth', sans-serif;
  }
  
  .draggable-todo .todo-item {
    width: 90%; /* Ensure the todo item takes up full width */
  }
  