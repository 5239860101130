@font-face {
    font-family: 'bree-serif';
    src: url('/BreeSerif-Regular.ttf') format('truetype');
  }


.big-wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    width: 100%;
   
}

.headline {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    min-height: 30px;
    width: 97%;
    background-color: rgba(240, 230, 230, 0.7);
    /* font-family: "bree-serif"; */
    font-family: "hellmuth";
    padding-left: 3px;
    border-radius: 10px;
    margin-top: 3px;
    margin-left: 5px;
    font-size: .9rem;
    font-weight: bold;
    text-decoration: none;
}

.article-content-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    height: 80%;
    width: 100%;
    margin-bottom: 3px;
    
}

.left-side-article-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    width: 50%;
    
}

.right-side-article-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 160px;
    width: 49%;
    background-color: rgba(114, 111, 111, 0.7);
    border-radius: 20px;
    margin-top: 4px;
    margin-bottom: 2px;
}

.image-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 110px;
    width: 100%;
    margin-top: 6px;
    margin-left: 3px;
    
}

.article-image {
    width: 96%; 
    height: auto; 
    border-radius: 10px; 
    object-fit: cover;
    margin-bottom: 3px; 
}

.image-caption {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: auto;
    width: 90%;
    background-color: rgba(114, 111, 111, 0.7);
    border-radius: 10px;
    margin-left: 3px;
    font-size: .5rem;
    padding: 5px;
    line-height: .9;
    text-align: justify;
    margin-top: 3px;
}

.subheadlines {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 7%;
    width: 100%;
    font-family: "bree-serif";
    font-size: .6rem;
    line-height: .9;
    text-align: left;
    padding: 7px;
    
}

.byline {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    height: 3%;
    width: 100%;
    padding: 5px;
    font-size: .5rem;
    font-family: "hellmuth";

}

.article-copy {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    height: 65%;
    width: 95%;
    padding: 5px;
    font-size: .6rem;
    text-align: justify;
    
}

.featured-article-link {
    text-decoration: none; /* Removes underline on links */
    color: inherit; /* Ensures the text keeps the same color */
    display: block; /* Ensures it behaves like a block element */
    text-decoration: none;
}

.article-copy {
    display: -webkit-box;
    -webkit-line-clamp: 9; /* Adjust the number of lines here */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: none;
    position: relative; /* Make the container a positioning context */
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
}

.article-copy::after {
    content: ""; 
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 70px; /* Adjust this height as needed */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(114, 111, 111, 0.7) 100%);
    pointer-events: none; /* Prevent the gradient from blocking interaction */
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    width: 99%;
}

.read-more {
    position: absolute; /* Place it relative to the parent .article-copy */
    bottom: 1px; /* Adjust this value to place the button near the bottom */
    right: 50px; /* Place it near the right edge */
    height: 20px;
    width: 60px;
    z-index: 2; /* Make sure it's above the text */
    cursor: pointer; /* Change cursor to pointer for interactivity */
}

.featured-article-link, 
.individual-art-container, 
.individual-art-container-2, 
.individual-art-container-3 {
    text-decoration: none; /* Removes underline on all article links */
    color: inherit; /* Ensures the text keeps the same color */
}

.featured-article-link a,
.individual-art-container a,
.individual-art-container-2 a,
.individual-art-container-3 a {
    text-decoration: none; /* Specifically targets any nested links */
    color: inherit;
}

a {
    text-decoration: none; /* Targets all anchor tags */
    color: inherit;
}

