.scroll-detail-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 110vw;
}

.scroll-detail {
  flex: 1;
  background-size: cover;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
}

.scroll-text {
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  outline: none;
  resize: none;
  padding: 20px;
  box-sizing: border-box;
  font-family: 'Hellmuth', sans-serif;
  font-size: 1.5rem;
  color: black;
}

.hamburger-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 33%;
}

.hamburger-icon-image {
  width: 50%;
  margin: 5px 0 10px;
  cursor: pointer;
}

/* Add unique backgrounds for each scroll */
.scroll-detail-page.paper-1 {
  background-image: url('/paper-1.jpg');
}

.scroll-detail-page.paper-2 {
  background-image: url('/paper-2.jpg');
}

.scroll-detail-page.paper-3 {
  background-image: url('/paper-3.jpg');
}

.scroll-detail-page.paper-4 {
  background-image: url('/paper-4.jpg');
}

.scroll-detail-page.paper-5 {
  background-image: url('/paper-5.jpg');
}

