/* src/components/Modal/Modal.css */
@font-face {
  font-family: 'hellmuth';
  src: url('/hellmuth.ttf') format('truetype');
}

@font-face {
  font-family: 'hellscourt';
  src: url('/Hellscourt.ttf') format('truetype');
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 1); /* Add drop shadow */
}
.modal-content {
  background: url('/modal-content-image.jpg') no-repeat center center;
  background-size: cover;
  padding: 20px;
  border-radius: 30px;
  width: 300px;
  max-width: 80%;
}

.modal-header {
  margin-bottom: 10px;
  font-family: 'hellmuth', sans-serif;
  
  
}

.modal-title {
  font-family: 'hellmuth', sans-serif;
  color: black; /* Change the font color to black */
  text-shadow: 2px 2px 4px rgb(248, 4, 4); /* Add a white drop shadow */
  letter-spacing: 2px; /* Increase the letter spacing */
  font-weight: bolder;
}

.modal-footer {
  text-align: right;
  margin-top: 15px;
}

.button {
  background: url('/close-button.jpg') no-repeat center center;
  background-size: cover;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  font-family: 'hellmuth', sans-serif;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 1); /* Add drop shadow */
}

.button:hover {
  opacity: 0.8;
}

.auth-switch {
  margin-top: 20px;
  text-align: center;
  font-family: 'hellscourt', sans-serif;
  color: rgb(248, 244, 244); /* Change the font color to black */
  text-shadow: 2px 2px 4px rgb(14, 13, 13); /* Add a white drop shadow */
  font-size: 1.5rem;
}

.auth-switch p {
  margin: 0;
}

.switch-button {
  background: none;
  border: none;
  color: white;
  background-color: grey;
  cursor: pointer;
  font-family: 'hellscourt', sans-serif;
  padding: 5px;
  padding-top: 7px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 1); /* Add drop shadow */
}

.switch-button:hover {
  opacity: 0.8;
}

