/* src/components/WelcomePage/WelcomePage.css */

@font-face {
    font-family: 'hellmuth';
    src: url('/hellmuth.ttf') format('truetype');
  }
  
  .welcome-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    
  }
  
  .welcome-image {
    width: 80%;
    max-width: 500px;
    border-radius: 15px;
  }
  
  .close-button {
    background-color: grey;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-family: 'hellmuth', sans-serif;
    margin-top: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 1); /* Add drop shadow */
  }
  
  .close-button:hover {
    opacity: 0.8;
  }
  