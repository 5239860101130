@font-face {
  font-family: 'hellmuth';
  src: url('/hellmuth.ttf') format('truetype');
}

.side-nav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 2; /* Keep it below the footer but visible */
  top: 0;
  left: 0;
  background-image: url('/nav-bar-satan-app.png');
  overflow-y: auto; /* Ensure it can scroll if needed */
  transition: 0.5s;
  padding-top: 20px;
  background-repeat: no-repeat;
}

.side-nav.visible {
  width: 300px;
  background-repeat: no-repeat;
  height: 100%; /* Ensure full height */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

/* Other existing styles remain unchanged */

.user-styles {
  font-family: 'hellmuth'; /* Use the correct font-family name */
  padding: 8px 8px 8px 12px;
  text-decoration: none;
  font-size: 20px;
  color: #818181;
  display: flex;
  flex-direction: column;
  transition: 0.3s;
  width: 20%;
  justify-content: flex-start;
  align-items: flex-start;
}

.other-drop-down-styles {
  font-family: 'hellmuth'; /* Use the correct font-family name */
  padding: 8px 8px 8px 12px;
  text-decoration: none;
  font-size: 15px;
  color: #818181;
  display: flex;
  flex-direction: column;
  transition: 0.3s;
  width: 50%;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
}

.side-nav a:hover {
  color: #f1f1f1;
}

.side-nav .close-btn {
  top: 0;
  right: 25px;
  font-size: 26px;
  margin-left: 12px;
  background: none;
  border: none;
  color: #818181;
  cursor: pointer;
  width: 5%;
}

.side-nav .close-btn:hover {
  color: #f1f1f1;
}

.user-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center; /* Adjusted alignment to center */
  height: 5%;
  width: 80%;
}

.avatar-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 17%;
  margin-left: 5px;
}

.search-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 20%;
}

.separator-line-satan-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: 5%;
  width: 20%;
}

.separator-line-satan-image {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: 3%;
  width: 60%;
}

.category-input,
.category-edit-input {
  font-family: 'hellmuth'; /* Use the correct font-family name */
  margin: 10px;
  padding: 8px;
  width: 80%;
  font-size: 15px;
  border: none;
  border-bottom: 2px solid #818181;
  background-color: black;
  color: white;
}

.category-edit-input:focus {
  outline: none;
}

.category-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  justify-content: space-between;
  width: 55%;
  padding: 1px 5px;
  color: #818181;
}

.category-count {
  font-family: 'hellmuth'; /* Use the correct font-family name */
  font-size: 15px;
  color: #818181;
}

.delete-icon,
.edit-icon {
  width: 20px;
  cursor: pointer;
}

.edit-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: 8px;
}

/* Add to your SideNav.css */
.categories-list {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.category-container {
  margin-bottom: 0px;
}

.user-display-name {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%; /* Ensure full width */
}

.edit-icon-user {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.user-display-name:hover .edit-icon-user {
  opacity: 1;
}

.user-edit-input {
  font-family: 'hellmuth';
  font-size: 20px; /* Match the font size with .user-styles */
  padding: 5px;
  width: 70%; /* Adjust width to fit the container */
  border: none;
  border-bottom: 2px solid #818181;
  background-color: black;
  color: white;
}

.side-nav .other-drop-down-styles {
  font-family: 'hellmuth';
  padding: 8px 8px 8px 12px;
  text-decoration: none;
  font-size: 15px;
  color: #818181 !important;  /* Add !important to ensure the color stays as intended */
  display: flex;
  flex-direction: column;
  transition: 0.3s;
  width: 50%;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
}
